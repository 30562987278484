<!-- 注文の詳細情報を表示し、編集、削除するためのダイアログ -->
<template>
  <div>
    <v-dialog
      v-if="currentItem"
      v-model="dialogModel"
      min-width="640px"
      :max-width="'90%'"
      @input="closeDialog"
    >
      <v-form>
        <v-card>
          <v-card-title class="primary">
            <div >
              {{dialogTitle}}
            </div>
            <v-spacer></v-spacer>
            <v-btn color="white" v-if="currentMode == 'edit'" @click="viewItemForm">
              <v-icon small >details</v-icon> &nbsp;詳細
            </v-btn>
            <v-btn color="white" v-if="currentMode == 'view'" @click="editItemForm">
              <v-icon small >edit</v-icon> &nbsp;編集
            </v-btn>
            <v-btn color="error" class="ml-4" v-if="currentItem.status == 0 && currentMode != 'new' && currentMode != 'collect'" @click="deleteItem(currentItem)">
              <v-icon small >delete</v-icon> &nbsp;削除
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">

                <!-- デバイス入力欄 -->
                <div class="text-subtitle-1 mt-4 ml-1" v-if="currentMode != 'collect'">
                  {{
                    currentMode == 'new' ? 'デバイスのバーコード読み取り'
                      : currentMode == 'lose' ? 'デバイスIDの入力'
                      : currentMode == 'traceability' ? currentItem.type === 'dongle' ? 'ロットID' : 'デバイスID'
                      : '封筒、もしくは行動記録用紙のバーコードの読み取り'
                    }}
                </div>
                <v-card class="mb-2" outlined v-if="currentMode != 'collect'">
                  <v-card-text>
                    <p class="text-caption mb-0 blue--text" style="white-space:pre-wrap; word-break:break-all;">
                      {{
                        currentMode == 'dispose' ? '下記の入力欄を選択してから、廃棄するホルター心電計の封筒、もしくは行動記録用紙のバーコードをバーコードリーダーで読み取ってください。'
                          : currentMode == 'lose' ? '紛失記録するデバイスのID（ホルター心電計：T-6桁のシリアル番号）を入力するか、封筒又は行動記録用紙のバーコードをバーコードリーダーで読み取ってください。'
                          : currentMode == 'new' ? '下記の入力欄を選択してから、バーコードをバーコードリーダーで読み取ってください。'
                          : ''
                      }}
                    </p>
                    <v-row>
                      <v-col cols="12" sm="3" v-if="currentMode != 'collect' && currentMode != 'dispose' && currentMode != 'lose' && currentMode != 'new'">
                        <v-select
                          class="fit"
                          v-model="currentItem.type" :items="types" item-text="name" item-value="value"
                          label="種別" no-data-text="選択対象がありません。"
                          ref="type"
                          :readonly="currentMode != 'new'"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="4">

                        <v-text-field
                          v-if="currentItem.type=='dongle'"
                          type="text"
                          v-model="currentItem.lot_id"
                          readonly
                        />

                        <v-text-field
                          v-else
                          type="email"
                          v-model="inputDeviceId"
                          ref="deviceIdTextField"
                          :rules="unreadableBarcode ? [] : deviceIdRules"
                          @input="checkDeviceId('ecg')"
                          :readonly="currentMode == 'view' || currentMode == 'edit' || currentMode == 'traceability'"
                          :disabled="unreadableBarcode"
                          :clearable="currentMode != 'edit' && currentMode != 'view' && currentMode != 'traceability'"
                          @keydown="checkZenHanKey"
                          @keyup="checkZenHanKey"
                          autocomplete="off"
                        />

                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field
                          v-if="currentItem.type != 'dongle'"
                          type="text"
                          :value="inputEcgType"
                          label="型式"
                          readonly
                        />
                      </v-col>
                      <v-col v-if="currentMode == 'collect'" cols="12" sm="3" >
                        <v-checkbox v-model="unreadableBarcode" label="読み取り不能" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <!-- デバイス入力欄（受付記録） -->
                <div class="text-subtitle-1 mt-4 ml-1" v-if="currentMode == 'collect'">
                  {{ '封筒のシリアル番号' }}
                </div>
                <v-card class="mb-2" outlined v-if="currentMode == 'collect'">
                  <v-card-text>
                    <p class="text-caption mb-0 blue--text" style="white-space:pre-wrap; word-break:break-all;">
                      {{ '下記の入力欄を選択してから、受付する封筒のバーコードをバーコードリーダーで読み取ってください。\n※ 汚れ・破損等で読み取りできない場合は「読み取り不能」にチェックを入れてください。' }}
                    </p>
                    <v-row>
                      <v-col cols="12" sm="4">

                        <v-text-field
                          v-if="currentItem.type=='dongle'"
                          type="text"
                          v-model="currentItem.lot_id"
                          readonly
                        />

                        <v-text-field
                          v-else
                          type="email"
                          v-model="inputDeviceId"
                          ref="deviceIdTextField"
                          :rules="unreadableBarcode ? [] : serialNumRules"
                          @input="checkDeviceId('ecg')"
                          :readonly="currentMode == 'view' || currentMode == 'edit' || currentMode == 'traceability'"
                          :disabled="unreadableBarcode"
                          :clearable="currentMode != 'edit' && currentMode != 'view' && currentMode != 'traceability'"
                          @keydown="checkZenHanKey"
                          @keyup="checkZenHanKey"
                          autocomplete="off"
                        />

                      </v-col>
                      <v-col v-if="currentMode == 'collect'" cols="12" sm="3" >
                        <v-checkbox v-model="unreadableBarcode" label="読み取り不能" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <!-- デバイス入力欄（行動記録用紙） -->
                <div v-if="currentMode == 'collect'">
                  <div class="text-subtitle-1 mt-4 mr-6" style="display: inline-block;" >行動記録用紙のシリアル番号</div>
                  <v-checkbox style="display: inline-block; vertical-align: middle;" v-model="notContainsActivityLog" label="行動記録用紙無し" />
                  <v-card class="mb-5" style="margin-top: -1rem;" outlined>
                    <v-card-text>
                      <p class="text-caption mb-0 blue--text" style="white-space:pre-wrap; word-break:break-all;">
                        {{'下記の入力欄を選択してから、受付する行動記録用紙のバーコードをバーコードリーダーで読み取ってください。\n※ 汚れ・破損等で読み取りできない場合は「読み取り不能」にチェックを入れてください。'}}
                      </p>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            type="email"
                            v-model="inputDeviceIdFromActivityLog"
                            ref="deviceIdFromActivityLogTextField"
                            :rules="notContainsActivityLog || unreadableBarcodeFromActivityLog ? [] : serialNumRules"
                            @input="checkDeviceId('activity_log')"
                            :readonly="currentMode == 'view' || currentMode == 'edit' || currentMode == 'traceability'"
                            :disabled="notContainsActivityLog || unreadableBarcodeFromActivityLog ? true : false"
                            :clearable="currentMode != 'edit' && currentMode != 'view' && currentMode != 'traceability'"
                            @keydown="checkZenHanKey"
                            @keyup="checkZenHanKey"
                            autocomplete="off"
                          />
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-checkbox v-model="unreadableBarcodeFromActivityLog" label="読み取り不能" :disabled="notContainsActivityLog ? true : false" />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>

                <!-- 製造日、使用期限日 -->
                <!-- <v-row v-if="currentMode != 'collect' && currentMode != 'dispose' && currentMode != 'lose'"> -->
                  <!-- 製造日 -->
                  <!-- <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-menu
                      v-model="producedDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="producedDateStr"
                          :label="dateFieldReadOnly && currentMode == 'new' ? '製造日（表示のみ）' : '製造日'"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          :rules="currentMode != 'new' ? [] : producedDateRules"
                          ref="producedDateField"
                          :hint="dateFieldReadOnly ? null : '例）2021/09/30'"
                          :persistent-hint="!dateFieldReadOnly"
                          :readonly="dateFieldReadOnly"
                          :disabled="currentItem.type !== 'holter'"
                          @focus="producedDateCalStr = getCalendarStr(producedDateStr)"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-if="!dateFieldReadOnly"
                        v-model="producedDateCalStr"
                        @input="producedDateMenu = false"
                        locale="jp-ja"
                        :day-format="date => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col> -->
                  <!-- 使用期限日 -->
                  <!-- <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-menu
                      v-model="expirationDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="expirationDateStr"
                          :label="dateFieldReadOnly && currentMode == 'new' ? '使用期限日（表示のみ）' : '使用期限日'"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          :rules="currentMode != 'new' ? [] : expirationDateRules"
                          ref="expirationDateField"
                          :hint="dateFieldReadOnly ? null : '例）2021/09/30'"
                          :persistent-hint="!dateFieldReadOnly"
                          :readonly="dateFieldReadOnly"
                          :disabled="currentItem.type !== 'holter'"
                          @focus="expirationDateCalStr = getCalendarStr(expirationDateStr)"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-if="!dateFieldReadOnly"
                        v-model="expirationDateCalStr"
                        @input="expirationDateMenu = false"
                        locale="jp-ja"
                        :day-format="date => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col> -->
                  <!-- <v-spacer></v-spacer> -->
                <!-- </v-row> -->

                <!-- その他 -->
                <v-row>
                  <v-col cols="12" sm="4" v-if="currentMode == 'collect' || (currentMode == 'edit' && item.status == 5)|| (currentMode == 'view' && item.status == 5)" >
                    <v-checkbox v-model="notContainsEcg" label="ホルター心電計無し" :readonly="currentMode == 'view'" />
                  </v-col>
                  <v-col cols="12" sm="4" v-if="(currentMode == 'edit' || currentMode == 'view') && (item.status == 5 || item.status == 6 || item.status == 7 || item.status == 11)" >
                    <v-checkbox v-model="notContainsActivityLog" label="行動記録用紙無し" :readonly="currentMode == 'view'" />
                  </v-col>
                </v-row>

                <!-- 医療機関 -->
                <v-row>
                  <v-col cols="12" sm="4" v-if="currentMode == 'collect' || currentMode == 'dispose'|| currentMode == 'lose'">
                    <v-text-field
                      readonly
                      v-model="customerOrgName"
                      label="医療機関（表示のみ）" />
                  </v-col>
                </v-row>

                <!-- 備考 -->
                 <v-row dense align="center">
                  <v-col>
                    <v-textarea
                      v-model="currentItem.remarks"
                      label="備考"
                      rows="3"
                      :readonly="currentMode == 'view' || currentMode == 'traceability'"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>

                <!-- 登録日時、最終更新日時 -->
                <v-row dense align="center">
                  <v-col cols="12" md="3" v-if="currentMode == 'view' || currentMode == 'traceability'" >
                    <v-text-field
                      v-model="currentItem.created"
                      label="登録日時"
                      :readonly="true"
                    />
                  </v-col>
                  <v-col cols="12" md="3" v-if="currentMode == 'view' || currentMode == 'traceability'" >
                    <v-text-field
                      v-model="currentItem.modified"
                      label="最終更新日時"
                      :readonly="true"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="primary darken-1" text @click="closeDialog">閉じる</v-btn>
            <v-btn v-if="currentMode == 'edit'" color="warning darken-1" text @click="onClickActionBtn('saveItem', currentItem)" :disabled="isActionBtnDisabled">保存</v-btn>
            <v-btn v-if="currentMode == 'new'" color="warning darken-1" text @click="onClickActionBtn('newItem', currentItem)" :disabled="isActionBtnDisabled">入荷を記録する</v-btn>
            <v-btn v-if="currentMode == 'new'" color="warning darken-1" text @click="onClickActionBtn('newItem', currentItem, true)" :disabled="isActionBtnDisabled" ref="recordContinueButton">入荷を連続記録する</v-btn>
            <v-btn v-if="currentMode == 'collect'" color="warning darken-1" text @click="onClickActionBtn('collectItem', currentItem)" :disabled="isActionBtnDisabled">受付を記録する</v-btn>
            <v-btn v-if="currentMode == 'collect'" color="warning darken-1" text @click="onClickActionBtn('collectItem', currentItem, true)" :disabled="isActionBtnDisabled" ref="recordContinueButton">受付を連続記録する</v-btn>
            <v-btn v-if="currentMode == 'dispose'" color="warning darken-1" text @click="onClickActionBtn('disposeItem', currentItem)" :disabled="isActionBtnDisabled">廃棄を記録する</v-btn>
            <v-btn v-if="currentMode == 'dispose'" color="warning darken-1" text @click="onClickActionBtn('disposeItem', currentItem, true)" :disabled="isActionBtnDisabled" ref="recordContinueButton">廃棄を連続記録する</v-btn>
            <v-btn v-if="currentMode == 'lose'" color="warning darken-1" text @click="onClickActionBtn('loseItem', currentItem)" :disabled="isActionBtnDisabled">紛失を記録する</v-btn>
            <v-btn v-if="currentMode == 'lose'" color="warning darken-1" text @click="onClickActionBtn('loseItem', currentItem, true)" :disabled="isActionBtnDisabled" ref="recordContinueButton">紛失を連続記録する</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>

import dateformat from 'dateformat';
import DeviceId from '@/mixins/DeviceId.js';

let itemsName = 'devices';
let itemsRef = null;

export default {
  name: 'InventoryDialog',
  components: {
  },
  props: {
    value: Boolean,
    item: Object,
    mode: {
      type: String,
      default: 'none'
    },
    staffType: {
      type: String,
      default: 'management'
    },
    dialogTitle: {
      type: String,
      default: '入荷記録'
    }
  },
  mixins: [
    DeviceId,
  ],
  data() {
    return {
      diffFlag: false,
      holtersPrice: 0,
      donglesPrice: 0,
      customerOrgs: this.$store.state.customerOrgs,
      customerStaffs: null,
      destinationCustomerStaffs: null,
      centers: this.$store.state.centers,
      staffs: [],
      dialogModel: false,
      currentItem: null,
      currentMode: 'none',
      deviceIdRules: [
        v => {
              if (!v || v.trim() === '') return '入力は必須です。';
              v = v.trim();
              v = v.replace(/\x1d/, '');  // Windows + Tera(HW0002)だと制御コード(FNC1)は0x1dのバイナリで入力される
              if (this.REG_OBJ_GS1_CDS.ECG_WITH_FNC1.test(v)) return true; // MAC + Tera(HW0002)だと制御コード(FNC1)は文字'029'で入力される
              if (this.REG_OBJ_DEVICE_IDS.ECG.test(v)) return true;
              if (this.REG_OBJ_GS1_CDS.ECG.test(v)) return true;
              return 'デバイスID、もしくはバーコードの形式が正しくありません。デバイスIDの例）T-000001'
            },
      ],
      serialNumRules: [
        v => {
              if (!v || v.trim() === '') return '入力は必須です。';
              v = v.trim();
              v = v.replace(/\x1d/, '');  // Windows + Tera(HW0002)だと制御コード(FNC1)は0x1dのバイナリで入力される
              if (this.REG_OBJ_GS1_CDS.ECG_WITH_FNC1.test(v)) return true; // MAC + Tera(HW0002)だと制御コード(FNC1)は文字'029'で入力される
              if (this.REG_OBJ_SERIAL_IDS.ECG.test(v)) return true;
              if (this.REG_OBJ_GS1_CDS.ECG.test(v)) return true;
              return 'シリアル番号、もしくはバーコードの形式が正しくありません。シリアル番号の例）000001'
            },
      ],
      expirationDateRules: [
        v => {
              if (!v || v.trim() === '') {
                if (this.dateFieldReadOnly) return true;
                return '入力は必須です。';
              }
              if (
                !/^2[0-9]{3}\/[0-9]{1,2}\/[0-9]{1,2}$/.test(v)
                || !this.isDate(v)
              ) return '日付が正しくありません。';
              if (new Date(v) < new Date()) return 'ホルター心電計の使用期限が過去の日付になっています。利用できません。';
              return true;
            },
      ],
      producedDateRules: [
        v => {
              if (!v || v.trim() === '') {
                if (this.dateFieldReadOnly) return true;
                return '入力は必須です。';
              }
              if (
                !/^2[0-9]{3}\/[0-9]{1,2}\/[0-9]{1,2}$/.test(v)
                || !this.isDate(v)
              ) return '日付が正しくありません。';
              if (new Date() < new Date(v)) return 'ホルター心電計の製造日が未来の日付になっています。利用できません。';
              return true;
            },
      ],
      nameRules: [
        v => !!v || '入力は必須です。',
      ],
      postalCodeRules: [
        v => !!v || '入力は必須です。',
      ],
      prefRules: [
        v => !!v || '入力は必須です。',
      ],
      cityRules: [
        v => !!v || '入力は必須です。',
      ],
      uniqueIdRules: [],
      remarksRules: [],
      remarks: '',
      types: [
        {
          name: 'ホルター心電計',
          value: 'holter'
        },
        {
          name: '通信用ドングル／ソフト',
          value: 'dongle'
        }
      ],
      notContainsActivityLog: false,
      notContainsEcg: false,
      customerOrgName: null,
      isActionBtnDisabled: false,  // ボタン無効/有効フラグ
      expirationDateMenu: false,
      expirationDateStr: '',
      expirationDateCalStr: '',
      producedDateMenu: false,
      producedDateStr: '',
      producedDateCalStr: '',
      dateFieldReadOnly: true,
      unreadableBarcode: false,
      unreadableBarcodeFromActivityLog: false,
      inputDeviceId: null,
      inputDeviceIdFromActivityLog: null,
      inputEcgType: null,
      inputEcgTypeFromActivityLog: null,
    }
  },
  methods: {
    closeDialog() {
      // console.log('closeDialog');
      this.dialogModel = false;
      this.$emit('input', false);
      this.$emit('closed', this.currentItem);
    },
    initItem() {
      this.inputDeviceId = null;
      this.inputDeviceIdFromActivityLog = null;
      this.inputEcgType = null;
      this.inputEcgTypeFromActivityLog = null;
      this.expirationDateStr =  '';
      this.expirationDateCalStr = '';
      this.producedDateStr = '';
      this.producedDateCalStr = '';
      this.unreadableBarcode = false;
      this.unreadableBarcodeFromActivityLog = false;
      this.notContainsActivityLog = false;
      this.notContainsEcg = false;
      this.customerOrgName = null;
      this.currentItem = {
        type	:	'none',
        device_id	:	null,
        center_id	:	this.$store.state.user.role.startsWith('center') ? this.$store.state.user.org_id: null,
        reception_center_id : null,
        produced	:	null,
        arrived	:	null,
        arrived_staff_id	:	null,
        shipped	:	null,
        shipped_staff_id	:	null,
        received1	:	null,
        received1_staff_id	:	null,
        received2	:	null,
        received2_staff_id	:	null,
        patient_attached	:	null,
        patient_attached_staff_id	:	null,
        patient_id: null,
        collected: null,
        device_collected	:	null,
        device_collected_staff_id	:	null,
        log_collected	:	null,
        log_collected_staff_id	:	null,
        device_uploaded	:	null,
        device_uploaded_staff_id	:	null,
        device_upload_error_info: null,
        log_uploaded	:	null,
        log_uploaded_staff_id	:	null,
        log_upload_error_info: null,
        disposed	:	null,
        disposed_staff_id	:	null,
        order_org_id	:	null,
        order_id	:	null,
        analysis_id	:	null,
        is_lost	:	false,
        remarks	:	'',
        created	:	null,
        modified	:	null,
        deleted	:	null,
        expiration_date : null,
        keeped: null,
        ec_order_id: null,
        ecg_type: null,
      };
    },
    newItemForm() {
      this.mode = 'new';
      this.initItem();
      this.dialogModel = true;
      setTimeout(() => {
        let deviceIdTextField = this.$refs['deviceIdTextField'];
        deviceIdTextField.focus()
      }, 300);
    },
    viewItemForm() {
      this.currentMode = 'view';
      // this.dialogTitle = '詳細';
      this.currentItem = JSON.parse(JSON.stringify(this.item));
      if (this.currentItem.expiration_date && this.currentItem.expiration_date.trim() != '') {
        this.expirationDateStr = this.currentItem.expiration_date;
      }
      if (this.currentItem.produced && this.currentItem.produced.trim() != '') {
        this.producedDateStr = this.currentItem.produced;
      }
      this.inputDeviceId = this.currentItem.device_id;
      this.inputEcgType = this.ecgType2Text(this.currentItem);
      this.dialogModel = true;
      this.notContainsEcg = 'device_collected' in this.currentItem && this.currentItem.device_collected != null ? false : true;
      this.notContainsActivityLog = 'log_collected' in this.currentItem && this.currentItem.log_collected != null ? false : true;
      this.writeLog(['devices', 'view'], `詳細画面を開きました。`, this.item); // 操作記録
    },
    traceabilityItemForm() {
      this.currentMode = 'traceability';
      this.currentItem = JSON.parse(JSON.stringify(this.item));
      if (this.currentItem.expiration_date && this.currentItem.expiration_date.trim() != '') {
        this.expirationDateStr = this.currentItem.expiration_date;
      }
      if (this.currentItem.produced && this.currentItem.produced.trim() != '') {
        this.producedDateStr = this.currentItem.produced;
      }
      this.inputDeviceId = this.currentItem.device_id;
      this.inputEcgType = this.ecgType2Text(this.currentItem);
      this.dialogModel = true;
      this.writeLog(['devices', 'view'], `詳細画面を開きました。`, this.item); // 操作記録
    },
    editItemForm() {
      this.currentMode = 'edit';
      this.currentItem = JSON.parse(JSON.stringify(this.item));
      if (this.currentItem.expiration_date && this.currentItem.expiration_date.trim() != '') {
        this.expirationDateStr = this.currentItem.expiration_date;
      }
      if (this.currentItem.produced && this.currentItem.produced.trim() != '') {
        this.producedDateStr = this.currentItem.produced;
      }
      this.inputDeviceId = this.currentItem.device_id;
      this.inputEcgType = this.ecgType2Text(this.currentItem);
      this.dialogModel = true;
      this.notContainsEcg = 'device_collected' in this.currentItem && this.currentItem.device_collected != null ? false : true;
      this.notContainsActivityLog = 'log_collected' in this.currentItem && this.currentItem.log_collected != null ? false : true;
      this.writeLog(['devices', 'edit'], `更新画面を開きました。`, this.item); // 操作記録
    },
    // 受付記録
    collectItemForm() {
      this.initItem();
      this.dialogModel = true;
      this.notContainsActivityLog = false;
      this.notContainsEcg = false;
      setTimeout(() => {
        let deviceIdTextField = this.$refs['deviceIdTextField'];
        deviceIdTextField.focus()
      }, 300);
    },
    // 廃棄記録
    disposeItemForm() {
      this.dialogModel = true;
      this.initItem();
      setTimeout(() => {
        let deviceIdTextField = this.$refs['deviceIdTextField'];
        deviceIdTextField.focus()
      }, 300);
    },
    // 紛失記録
    loseItemForm() {
      this.dialogModel = true;
      this.initItem();
      setTimeout(() => {
        let deviceIdTextField = this.$refs['deviceIdTextField'];
        deviceIdTextField.focus()
      }, 300);
    },
    // 入荷記録
    async newItem(item, continueFlag = false) {
      this.$nextTick(async () => {
        this.newItemBody(item, continueFlag);
      });
    },
    async newItemBody(item, continueFlag = false) {
      console.log('newItem', item);
      this.currentMode = 'new';

      // 入力エラーがある場合は終了
      if (
        !this.$refs[`form`].validate()
        || this.$refs[`deviceIdTextField`].validations.length > 0
      ) return;
      let deviceId = this.inputDeviceId;

      if (deviceId.substr(0, 1) === this.DEVICE_TYPE_STRINGS.ECG) {
        // 心電計の場合
        // 使用期限日チェック
        let expirationDateField = this.$refs[`expirationDateField`];
        if (expirationDateField && expirationDateField.validations.length > 0) {
          await this.$root.$alert('エラー', expirationDateField.validations[0]);
          return;
        }
        if (!this.expirationDateStr || this.expirationDateStr.trim() === '') {
          await this.$root.$alert('エラー','使用期限日を入力してください。');
          expirationDateField.focus();
          return;
        }
        // 製造日チェック
        let producedDateField = this.$refs[`producedDateField`];
        if (producedDateField && producedDateField.validations.length > 0) {
          await this.$root.$alert('エラー', producedDateField.validations[0]);
          return;
        }
        if (!this.producedDateStr || this.producedDateStr.trim() === '') {
          await this.$root.$alert('エラー','製造日を入力してください。');
          producedDateField.focus();
          return;
        }
      }

      if (item.center_id == null) {
          await this.$root.$alert('エラー','販売組織のスタッフではありません。');
          return;
      }

      // itemの情報をFirebase上に追加登録する
      if (deviceId.substr(0, 1) === this.DEVICE_TYPE_STRINGS.ECG) {
        // 心電計の場合
        // 製造年月
        let producedDateStrs = this.producedDateStr.split('/');
        item.produced = new Date(
          parseInt(producedDateStrs[0]),
          parseInt(producedDateStrs[1]) - 1,
          parseInt(producedDateStrs[2])
        );
        // 使用期限(指定日の23時59分59秒)
        let expirationDateStrs = this.expirationDateStr.split('/');
        item.expiration_date = new Date(
          parseInt(expirationDateStrs[0]),
          parseInt(expirationDateStrs[1]) - 1,
          parseInt(expirationDateStrs[2]),
          23, 59, 59
        );
      }
      // その他
      item.device_id = deviceId;
      item.deleted = null;
      item.status = 0;
      item.arrived_staff_id = this.$store.state.user.id;
      item.created = this.$firebase.firestore.FieldValue.serverTimestamp();
      item.arrived = item.created;
      item.modified = item.created;
      try {
        let docRef = itemsRef.doc(deviceId) // deviceIdと一致させる。
        await this.$root.$progressive(docRef.set(item));
        this.dialogModel = false;
        this.writeLog(['device_new'], `入荷記録しました。`, item); // 操作記録
      } catch (e) {
        console.error(e);
        await this.$root.$alert('エラー', '新規注文の登録に失敗しました。');
      }

      // 在庫数を増やす
      const centerDocRef = this.$db.doc('staff_orgs/' + item.center_id);
      if (item.type == 'holter') {
        await centerDocRef.update({ holter_stock_quantity: this.$firebase.firestore.FieldValue.increment(1) });
      } else if (item.type == 'dongle') {
        await centerDocRef.update({ dongle_stock_quantity: this.$firebase.firestore.FieldValue.increment(1) });
      }

      if (continueFlag) {
        this.newItemForm();
      } else {
        this.$emit('input', false);
        this.$emit('closed', item);
      }
    },
    // 更新
    async saveItem(item) {
      this.$nextTick(async () => {
        this.saveItemBody(item);
      });
    },
    async saveItemBody(item) {
      const now = this.$firebase.firestore.FieldValue.serverTimestamp();

      // 種別とデバイスIDは変更不可
      let updateData = {
        center_id: item.center_id,
        remarks: item.remarks,
        modified: now,
        ec_order_id: item.ec_order_id && item.ec_order_id.trim() != '' ? item.ec_order_id.trim() : null,
      }
      if (item.status == 5 || item.status == 6 || item.status == 7 || item.status == 11) {
        // 行動記録用紙有無の変更
        if (
          this.notContainsEcg
          && 'device_collected' in item && item.device_collected != null && item.device_collected.trim() != ''
        ) {
          // 無し、かつ日時記録済みの場合
          updateData['device_collected'] = null;
          updateData['device_collected_staff_id'] =  null;
        } else if (
          !this.notContainsEcg
          && (!('device_collected' in item) || item.device_collected == null || item.device_collected.trim() == '')
        ) {
          // 有り、かつ日時未記録の場合
          updateData['device_collected'] = now;
          updateData['device_collected_staff_id'] =  this.$store.state.user.id;
        }

        // 行動記録用紙有無の変更
        if (
          this.notContainsActivityLog
          && 'log_collected' in item && item.log_collected != null && item.log_collected.trim() != ''
        ) {
          // 無し、かつ日時記録済みの場合
          updateData['log_collected'] = null;
          updateData['log_collected_staff_id'] =  null;
        } else if (
          !this.notContainsActivityLog
          && (!('log_collected' in item) || item.log_collected == null || item.log_collected.trim() == '')
        ) {
          // 有り、かつ日時未記録の場合
          updateData['log_collected'] = now;
          updateData['log_collected_staff_id'] =  this.$store.state.user.id;
        }
      }
      try {
        // {items}/{name}で登録
        const doc = await this.$root.$progressive(itemsRef.doc(item.id).get());
        let beforeData = doc.exists ? doc.data() : null;
        await this.$root.$progressive(itemsRef.doc(item.id).update(updateData));
        this.writeLog(['devices', 'update'], `更新しました。`, item, { before: beforeData, after: updateData });  // 操作記録
        this.dialogModel = false;
        this.$emit('input', false);
        this.$emit('closed', item);
      } catch (e) {
        console.error(e);
        await this.$root.$alert('エラー', `デバイス ${item.device_id} の保存に失敗しました。`);
      }
    },
    // 削除
    async deleteItem(item) {
      // 削除確認のダイアログを表示
      const result = await
        this.$root.$confirm(`確認`, `このデバイス ${item.device_id}を本当に削除しますか？`);

      if (result) {
        // 削除処理
        try {
          await this.$root.$progressive(itemsRef.doc(item.id)
            .update({deleted: this.$firebase.firestore.FieldValue.serverTimestamp()}));
          this.writeLog(['devices', 'delete'], `削除しました。`, item);  // 操作記録
          this.$emit('input', false);
          this.$emit('closed', item);
        } catch (e) {
          console.error(e);
          await this.$root.$alert('エラー', `デバイス ${item.email} の削除に失敗しました。`);
        }
      }
      this.closeDialog();
    },
    async collectItem(item, continueFlag = false) {
      this.$nextTick(async () => {
        this.collectItemBody(item, continueFlag);
      });
    },
    // 受付記録
    async collectItemBody(item, continueFlag = false) {
        console.log('collectItem', item);

      // 入力エラーがある場合は終了
      if (
        !this.$refs[`form`].validate()
        || this.$refs[`deviceIdTextField`].validations.length > 0
        || this.$refs[`deviceIdFromActivityLogTextField`].validations.length > 0
      ) return;

      // 封筒・行動記録用紙の両方を読み取り対象としていない場合はエラー
      if (
        (this.notContainsActivityLog || this.unreadableBarcodeFromActivityLog)
        && this.unreadableBarcode
      ) {
        await this.$root.$alert('エラー', '封筒もしくは行動記録用紙の、どちらか一方のバーコードを読み取る必要があります。');
        return;
      }

      // 封筒・行動記録用紙の両方を読み取り対象としており、かつ両方のデバイスIDが一致していない場合はエラー
      if (
        !this.notContainsActivityLog && !this.unreadableBarcodeFromActivityLog
        && !this.unreadableBarcode
        && this.inputDeviceId != this.inputDeviceIdFromActivityLog
      ) {
        await this.$root.$alert('エラー', '封筒・行動記録用紙の読み取った値が一致していません。');
        return;
      }

      let deviceId = null;
      if (!this.unreadableBarcode) {
        // 封筒のバーコードが読み取られている場合
        deviceId = this.inputDeviceId;
      } else if (!this.notContainsActivityLog && !this.unreadableBarcodeFromActivityLog) {
        // 行動記録用紙のバーコードが読み取られている場合
        deviceId = this.inputDeviceIdFromActivityLog;
      }
      if (deviceId == null) return; // ありえないパターン

      // デイバスの更新データ
      let updateData = {
        status: 5,
        collected: this.$firebase.firestore.FieldValue.serverTimestamp(),
        device_collected: !this.notContainsEcg ? this.$firebase.firestore.FieldValue.serverTimestamp() : null,
        device_collected_staff_id: !this.notContainsEcg ? this.$store.state.user.id : null,
        log_collected: !this.notContainsActivityLog ? this.$firebase.firestore.FieldValue.serverTimestamp() : null,
        log_collected_staff_id: !this.notContainsActivityLog ? this.$store.state.user.id : null,
        remarks: item.remarks,
      }

      // デバイスの登録データ
      let createData = {
        status: 5,
        collected: this.$firebase.firestore.FieldValue.serverTimestamp(),
        device_collected: !this.notContainsEcg ? this.$firebase.firestore.FieldValue.serverTimestamp() : null,
        device_collected_staff_id: !this.notContainsEcg ? this.$store.state.user.id : null,
        log_collected: !this.notContainsActivityLog ? this.$firebase.firestore.FieldValue.serverTimestamp() : null,
        log_collected_staff_id: !this.notContainsActivityLog ? this.$store.state.user.id : null,
        remarks: item.remarks != null ? item.remarks: '',
        type: 'holter',
        analysis_id: null,
        analyzed: null,
        arrived: null,
        arrived_staff_id: null,
        center_id: null,
        device_upload_error_info: null,
        device_uploaded: null,
        device_uploaded_staff_id: null,
        disposed: null,
        disposed_staff_id: null,
        is_lost: null,
        keeped: null,
        log_uploaded: null,
        log_uploaded_staff_id: null,
        ec_order_id: null,
        order_org_id: null,
        patient_attached: null,
        patient_attached_staff_id: null,
        patient_id: null,
        received: null,
        received_staff_id: null,
        received2: this.$firebase.firestore.FieldValue.serverTimestamp(),
        received2_staff_id: null,
        reception_center_id: this.$store.state.user.org_id,
        shipped: null,
        shipped_staff_id: null,
        created: this.$firebase.firestore.FieldValue.serverTimestamp(),
        modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
        deleted: null,
        device_id: `T-${deviceId}`,
        produced: null,
        expiration_date: null,
      }

      // データ更新
      let promise = this.$db.runTransaction(async (t) => {
        // デバイスデータのチェック
        let docRef = this.$db.doc('devices/' + `T-${deviceId}`);
        let doc = await t.get(docRef);
        if (!doc.exists) {
          // デバイスが存在しない場合、デバイスを登録する
          const deviceRef = this.$db.collection('devices').doc(`T-${deviceId}`);

          // デバイス受付センターの在庫を増やす
          const receptionCenterDocRef = this.$db.doc('staff_orgs/' + this.$store.state.user.org_id);
          const receptionCenterDoc = await t.get(receptionCenterDocRef);
          if (!receptionCenterDoc.exists) {
            return Promise.reject('本組織のデータが存在しません。');
          }
          const receptionCenterData = receptionCenterDoc.data();
          const holterStockQuantity = receptionCenterData.holter_stock_quantity == undefined || receptionCenterData.holter_stock_quantity == null ? 0 : parseInt(receptionCenterData.holter_stock_quantity);
          const updateReceptionCenteInventoryData = {
            holter_stock_quantity: holterStockQuantity + 1,
            modified: this.$firebase.firestore.FieldValue.serverTimestamp()
          };

          // デバイスの登録
          t = await t.set(deviceRef, createData);

          // デバイス受付センターの在庫数の更新（加算）
          t = await t.update(receptionCenterDocRef, updateReceptionCenteInventoryData);
        } else {
          let data = doc.data();

          // order_org_idの在庫を減らす（医療機関）
          let orgDocRef = this.$db.doc('customer_orgs/' + data.order_org_id);
          let orgDoc = await t.get(orgDocRef);
          if (!orgDoc.exists) {
            return Promise.reject('利用した組織のデータが存在しません。');
          }
          let orgData = orgDoc.data();
          let holter_stock_quantity = orgData.holter_stock_quantity - 1;
          if (holter_stock_quantity < 0) holter_stock_quantity = 0;
          let updateInventoryData = {
            holter_stock_quantity,
            modified: this.$firebase.firestore.FieldValue.serverTimestamp()
          };

          // デバイス受付センターの在庫を増やす
          const receptionCenterDocRef = this.$db.doc('staff_orgs/' + data.reception_center_id);
          const receptionCenterDoc = await t.get(receptionCenterDocRef);
          if (!receptionCenterDoc.exists) {
            return Promise.reject('本組織のデータが存在しません。');
          }
          const receptionCenterData = receptionCenterDoc.data();
          const holterStockQuantity = receptionCenterData.holter_stock_quantity == undefined || receptionCenterData.holter_stock_quantity == null ? 0 : parseInt(receptionCenterData.holter_stock_quantity);
          const updateReceptionCenteInventoryData = {
            holter_stock_quantity: holterStockQuantity + 1,
            modified: this.$firebase.firestore.FieldValue.serverTimestamp()
          };

          // 更新
          t = await t.update(orgDocRef, updateInventoryData);
          t = await t.update(docRef, updateData);
          t = await t.update(receptionCenterDocRef, updateReceptionCenteInventoryData);
        }
      });

      try {
        await this.$root.$progressive(promise);
        item.device_id = deviceId; // 操作記録のための情報追加
        this.writeLog(['device_collect'], `受付記録しました。`, item); // 操作記録
      } catch (e) {
        this.$root.$alert('エラー', '受付記録に失敗しました。' + e);
        console.error(e);
        return;
      }

      // 連続記録の場合とそうでない場合の対応
      if (continueFlag) {
        this.collectItemForm();
      } else {
        this.$emit('input', false);
        this.$emit('closed', item);
      }
    },
    // 廃棄記録
    async disposeItem(item, continueFlag = false) {
      this.$nextTick(async () => {
        this.disposeItemBody(item, continueFlag);
      });
    },
    // 廃棄記録
    async disposeItemBody(item, continueFlag = false) {
      console.log('disposeItem', item);

      // 入力エラーがある場合は終了
      if (
        !this.$refs[`form`].validate()
        || this.$refs[`deviceIdTextField`].validations.length > 0
      ) return;
      let deviceId = this.inputDeviceId;

      // 更新データ
      let updateData = {
        status: 9,
        disposed: this.$firebase.firestore.FieldValue.serverTimestamp(),
        modified:  this.$firebase.firestore.FieldValue.serverTimestamp(),
        disposed_staff_id: this.$store.state.user.id,
        remarks: item.remarks,
      }

      // データ更新
      let promise = this.$db.runTransaction(async (t) => {

        // デバイスデータ取得
        let docRef = this.$db.doc('devices/' + deviceId);
        let doc = await t.get(docRef);
        if (!doc.exists) {
          return Promise.reject('そのようなデバイスは存在しません。');
        }
        let data = doc.data();

        // デバイスの状態で処理が分かれる
        let orgDocRef = null;
        let updateInventoryData = null;
        let orgDoc = null;
        let orgData = null;
        switch (data.status) {
          case 1:
            return Promise.reject('医療機関に発送済みのデバイスのため、廃棄記録できません。');
          case 2:
            return Promise.reject('販売スタッフに発送済みのデバイスのため、廃棄記録できません。');
          case 3:
            return Promise.reject('医療機関に登録済みのデバイスのため、廃棄記録できません。');
          case 4:
            return Promise.reject('患者に装着後、受付していないデバイスのため、廃棄記録できません。');
          case 9:
            return Promise.reject('すでに廃棄されたデバイスのため廃棄記録できません。');
          case 10:
            return Promise.reject('すでに紛失記録されたデバイスのため廃棄記録できません。');
          case 0:
            // 入荷済み状態の場合はcenter_idの在庫を減らす
            orgDocRef = this.$db.doc('staff_orgs/' + data.center_id);
            orgDoc = await t.get(orgDocRef);
            if (!orgDoc.exists) {
              return Promise.reject(`販売組織(${data.center_id})のデータが存在しません。`);
            }
            orgData = orgDoc.data();
            updateInventoryData = {
              modified: this.$firebase.firestore.FieldValue.serverTimestamp()
            };
            if (data.type == 'holter'){
              let holter_stock_quantity = orgData.holter_stock_quantity - 1;
              if (holter_stock_quantity < 0) holter_stock_quantity = 0;
              updateInventoryData.holter_stock_quantity = holter_stock_quantity;
            } else if (data.type == 'dongle') {
              let dongle_stock_quantity = orgData.dongle_stock_quantity - 1;
              if (dongle_stock_quantity < 0) dongle_stock_quantity = 0;
              updateInventoryData.dongle_stock_quantity = dongle_stock_quantity;
            }
            break;
          case 5:
          case 6:
          case 7:
          case 11:
            if (!data.device_collected) {
              // 心電計の受付記録がされていない場合は、廃棄記録できない
              return Promise.reject(`心電計が受付されていないため廃棄記録できません。`);
            }
            // 受付センター着荷以降の場合はreception_center_idの在庫を減らす
            orgDocRef = this.$db.doc('staff_orgs/' + data.reception_center_id);
            orgDoc = await t.get(orgDocRef);
            if (!orgDoc.exists) {
              return Promise.reject(`デバイス受付センター(${data.reception_center_id})のデータが存在しません。`);
            }
            orgData = orgDoc.data();
            updateInventoryData = {
              modified: this.$firebase.firestore.FieldValue.serverTimestamp()
            };
            if (data.type == 'holter'){
              if (data.status == 5) {
                // 受付センター着荷状態の場合、受付数を減らす
                let tmp = orgData.holter_stock_quantity - 1;
                if (tmp < 0) tmp = 0;
                updateInventoryData.holter_stock_quantity = tmp;
              } else {
                // 保管状態の場合、保管数を減らす
                let tmp = orgData.holter_stock_quantity_keeped - 1;
                if (tmp < 0) tmp = 0;
                updateInventoryData.holter_stock_quantity_keeped = tmp;
              }
              // 廃棄数を増やす
              updateInventoryData['holter_stock_quantity_disposed'] = 1;
              if ('holter_stock_quantity_disposed' in orgData && orgData.holter_stock_quantity_disposed) {
                updateInventoryData['holter_stock_quantity_disposed'] = parseInt(orgData.holter_stock_quantity_disposed) + 1;
              }
            } else if (data.type == 'dongle') {
              let dongle_stock_quantity = orgData.dongle_stock_quantity - 1;
              if (dongle_stock_quantity < 0) dongle_stock_quantity = 0;
              updateInventoryData.dongle_stock_quantity = dongle_stock_quantity;
            }
            break;
          default:
            // 廃棄記録のみ行う
            t = await t.update(docRef, updateData);
            return Promise.resolve();
        }

        // 更新
        if (orgDocRef != null && updateInventoryData != null) t = await t.update(orgDocRef, updateInventoryData);
        t = await t.update(docRef, updateData);
      });

      try {
        await this.$root.$progressive(promise);
        item.device_id = deviceId; // 操作記録のための情報追加
        this.writeLog(['device_dispose'], `廃棄記録しました。`, item); // 操作記録
      } catch (e) {
        this.$root.$alert('エラー', '廃棄記録に失敗しました。' + e);
        console.error(e);
        return;
      }

      // 連続記録の場合とそうでない場合の対応
      if (continueFlag) {
        this.disposeItemForm();
      } else {
        this.$emit('input', false);
        this.$emit('closed', item);
      }
    },
    async loseItem(item, continueFlag = false) {
      this.$nextTick(async () => {
        this.loseItemBody(item, continueFlag);
      });
    },
    // 紛失記録
    async loseItemBody(item, continueFlag = false) {
      console.log('loseItem', item);

      // 入力エラーがある場合は終了
      if (
        !this.$refs[`form`].validate()
        || this.$refs[`deviceIdTextField`].validations.length > 0
      ) return;
      let deviceId = this.inputDeviceId;

      // 更新データ
      let updateData = {
        status: 10,
        lost: this.$firebase.firestore.FieldValue.serverTimestamp(),
        is_lost:  true,
        lost_staff_id: this.$store.state.user.id,
        remarks: item.remarks,
        modified:  this.$firebase.firestore.FieldValue.serverTimestamp(),
      }

      // データの更新
      let promise = this.$db.runTransaction(async (t) => {

        // デバイスデータ取得
        let docRef = this.$db.doc('devices/' + deviceId);
        let doc = await t.get(docRef);
        if (!doc.exists) {
          return Promise.reject('そのようなデバイスは存在しません。');
        }
        let data = doc.data();

        // デバイスの状態で処理が分かれる
        let orgDocRef = null;
        let updateInventoryData = null;
        let orgDoc = null;
        let orgData = null;
        switch (data.status) {
          case 9:
            return Promise.reject('すでに廃棄されたデバイスのため紛失記録できません。');
          case 10:
            return Promise.reject('すでに紛失記録されたデバイスのため紛失記録できません。');
          case 0:
            // 入荷済み状態の場合はcenter_idの在庫を減らす
            orgDocRef = this.$db.doc('staff_orgs/' + data.center_id);
            orgDoc = await t.get(orgDocRef);
            if (!orgDoc.exists) {
              return Promise.reject(`販売組織(${data.center_id})のデータが存在しません。`);
            }
            orgData = orgDoc.data();
            updateInventoryData = {
              modified: this.$firebase.firestore.FieldValue.serverTimestamp()
            };
            if (data.type == 'holter'){
              let tmp = orgData.holter_stock_quantity - 1;
              if (tmp < 0) tmp = 0;
              updateInventoryData.holter_stock_quantity = tmp;
            } else if (data.type == 'dongle') {
              let tmp = orgData.dongle_stock_quantity - 1;
              if (tmp < 0) tmp = 0;
              updateInventoryData.dongle_stock_quantity = tmp;
            }
            break;
          case 3:
          case 4:
            // 未使用の場合は医療機関の在庫を減らす
            orgDocRef = this.$db.doc('customer_orgs/' + data.order_org_id);
            orgDoc = await t.get(orgDocRef);
            if (!orgDoc.exists) {
              return Promise.reject(`医療機関(${data.order_org_id})のデータが存在しません。`);
            }
            orgData = orgDoc.data();
            updateInventoryData = {
              modified: this.$firebase.firestore.FieldValue.serverTimestamp()
            };
            if (data.type == 'holter'){
              let tmp = orgData.holter_stock_quantity - 1;
              if (tmp < 0) tmp = 0;
              updateInventoryData.holter_stock_quantity = tmp;
            } else if (data.type == 'dongle') {
              let tmp = orgData.dongle_stock_quantity - 1;
              if (tmp < 0) tmp = 0;
              updateInventoryData.dongle_stock_quantity = tmp;
            }
            break;
          case 5:
          case 6:
          case 7:
          case 11:
            // 受付センター着荷以降の場合はreception_center_idの在庫を減らす
            orgDocRef = this.$db.doc('staff_orgs/' + data.reception_center_id);
            orgDoc = await t.get(orgDocRef);
            if (!orgDoc.exists) {
              return Promise.reject(`デバイス受付センター(${data.reception_center_id})のデータが存在しません。`);
            }
            orgData = orgDoc.data();
            updateInventoryData = {
              modified: this.$firebase.firestore.FieldValue.serverTimestamp()
            };
            if (data.type == 'holter'){
              if (data.status == 5) {
                // 受付センター着荷状態の場合
                let tmp = orgData.holter_stock_quantity - 1;
                if (tmp < 0) tmp = 0;
                updateInventoryData.holter_stock_quantity = tmp;
              } else {
                // 保管状態の場合
                let tmp = orgData.holter_stock_quantity_keeped - 1;
                if (tmp < 0) tmp = 0;
                updateInventoryData.holter_stock_quantity_keeped = tmp;
              }
            } else if (data.type == 'dongle') {
              let tmp = orgData.dongle_stock_quantity - 1;
              if (tmp < 0) tmp = 0;
              updateInventoryData.dongle_stock_quantity = tmp;
            }
            break;
          default:
            // 紛失記録のみ行う
            t = await t.update(docRef, updateData);
            return Promise.resolve();
        }

        // 更新
        if (orgDocRef != null && updateInventoryData != null) t = await t.update(orgDocRef, updateInventoryData);
        t = await t.update(docRef, updateData);
      });

      try {
        await this.$root.$progressive(promise);
        item.device_id = deviceId; // 操作記録のための情報追加
        this.writeLog(['device_lose'], `紛失記録しました。`, item); // 操作記録
      } catch (e) {
        this.$root.$alert('エラー', '紛失記録に失敗しました。' + e);
        console.error(e);
        return;
      }

      // 連続記録の場合とそうでない場合の対応
      if (continueFlag) {
        this.collectItemForm();
      } else {
        this.$emit('input', false);
        this.$emit('closed', item);
      }
    },
    clearErrorMessages(ref = null) {
      let fieldRefs = [
        this.$refs['deviceIdTextField'],
        this.$refs['deviceIdFromActivityLogTextField']
      ];
      if (ref != null) fieldRefs = [ref];
      for (let fieldRef of fieldRefs) {
        if (fieldRef && fieldRef.errorMessages) {
          fieldRef.errorMessages.splice(0);
        }
      }
    },
    // デバイスID/GS1コードの入力チェック
    async checkDeviceId(inputType) {

      // 各種入力欄を一旦クリア
      this.currentItem.remarks = null;
      this.customerOrgName = null;
      this.currentItem.status = null;

      let deviceId = null;
      let textField = null;
      switch (inputType) {
        case 'ecg':
          deviceId = this.inputDeviceId == null ? '' : this.inputDeviceId.trim();
          textField = this.$refs['deviceIdTextField'];
          this.inputEcgType = null;
          break;
        case 'activity_log':
          deviceId = this.inputDeviceIdFromActivityLog == null ? '' : this.inputDeviceIdFromActivityLog.trim();
          textField = this.$refs['deviceIdFromActivityLogTextField'];
          this.inputEcgTypeFromActivityLog = null;
          break;
        default:
          return;
      }
      if (!deviceId) return;

      this.clearErrorMessages(textField);

      this.dateFieldReadOnly = true;
      this.expirationDateStr = null;
      this.producedDateStr = null;
      this.currentItem.type = 'none';

      // バリデーションがエラーの場合は終了
      if (!textField.validate()) return;

      // GS1コードの制御コード(FNC1)の削除
      // Windows + Tera(HW0002)だと制御コード(FNC1)は0x1dのバイナリで入力される
      deviceId = deviceId.replace(/\x1d/, '');

      // GS1コードの制御コード(FNC1)の削除
      // Mac + Tera(HW0002)だと制御コード(FNC1)は文字列'029'に変換されて入力される
      if (
        deviceId.trim().length == 42
        || deviceId.trim().length == 43
      ) {
        deviceId = String(deviceId.slice(0, 31)) + String(deviceId.slice(34));
      }

      if (
        deviceId.trim().length == 39
        || deviceId.trim().length == 40
      ) {
        // GS1コード入力の場合（FNC1無し）
        let returnCd = this.validateGs1(deviceId);
        if (!returnCd) {
          textField.errorMessages.push('バーコードの形式が正しくありません。');
          return;
        }

        // デバイスID設定
        deviceId = returnCd[4];

        // バーコードの表示をデバイスIDに更新する
        // ※ nextTick を挟んでいる理由
        // バーコード入力 → 表示されたデバイスIDをマウスで全選択 → そのままもう一度同じバーコードを読み込む
        // とすると、結果、バーコードの数字が表示されたままとなる
        // 同じデバイスIDを入力することになり、入力値に変更ないためVueによって表示が変更されないと思われる
        switch (inputType) {
          case 'ecg':
            this.$nextTick(() => {
              this.inputDeviceId = deviceId;
            });
            break;
          case 'activity_log':
            this.$nextTick(() => {
              this.inputDeviceIdFromActivityLog = deviceId;
            });
            break;
          default:
            return;
        }
      }

      if (
        deviceId && deviceId.trim().length == 6
        || deviceId && deviceId.trim().length == 7 
        || deviceId && deviceId.trim().length == 8
        || deviceId && deviceId.trim().length == 9
      ) {
        // デバイスID入力の場合

        // ホルター心電計のデバイスIDのフォーマットでなければ終了
        if (deviceId.match(this.REG_OBJ_DEVICE_IDS.ECG) === null && deviceId.match(this.REG_OBJ_SERIAL_IDS.ECG) === null) return;

        // ホルター心電計を設定
        this.currentItem.type = 'holter';

        // 対象のデバイスを探索する
        let query = null;
        if (this.mode == 'collect' || this.mode == 'dispose') {
          // 受付記録の場合
          // デバイスデータ取得
          if (this.mode == 'collect') {
          query = this.$db.collection('devices')
            .where('deleted', '==', null)
            .where('device_id', '==', `T-${deviceId}`);
          } else {
          // 廃棄・紛失記録の場合
          query = this.$db.collection('devices')
            .where('deleted', '==', null)
            .where('device_id', '==', deviceId);
          }
          if (this.$store.state.user.role.startsWith('reception')) {
            // デバイス受付センターのスタッフの場合
            query = query.where('reception_center_id', '==', this.$store.state.user.org_id);
          } else if (this.$store.state.user.role.startsWith('center')) {
            // 販売組織スタッフの場合
            query = query.where('center_id', '==', this.$store.state.user.org_id);
          }
          let snapshot = await query.get();

          let data = null;
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              data = doc.data(); // 複数存在してはならないが、存在していた場合は最後のドキュメントを選択
            });
          }

          // 未登録デバイスは終了(受付記録時は無視する)
          if (this.mode != "collect") {
            if (data == null) {
              textField.errorMessages.push('このセンターに登録されていないデバイスIDです');
              return;
            }

            // 各種データの設定
            switch (inputType) {
              case 'ecg':
                this.$nextTick(() => {
                  this.inputEcgType = this.ecgType2Text(data);
                });
              break;
              case 'activity_log':
                this.$nextTick(() => {
                  this.inputEcgTypeFromActivityLog = this.ecgType2Text(data);
                });
                break;
              default:
                return;
            }
          }

          if (this.mode == 'collect') {
            // 受付記録時(未登録デバイスの受付記録時は無視する)
            if (data != null) {
              if (data.reception_center_id !== this.$store.state.user.org_id) {
                // 該当受付センターのものではない場合
                textField.errorMessages.push('受付先が異なるデバイスのため記録できません。');
                return;
              }
              switch (data.status) {
                case 0:
                case 1:
                case 2:
                  textField.errorMessages.push('このデバイスIDは医療機関にまだ登録されていません。');
                  return;
                case 3:
                  textField.errorMessages.push('このデバイスIDは検査開始登録されていません。');
                  return;
                case 4:
                  break;
                case 5:
                case 6:
                case 7:
                case 11:
                  textField.errorMessages.push('このデバイスIDは受付センターに着荷しています。');
                  return;
                case 8:
                  break;
                case 9:
                  textField.errorMessages.push('廃棄されたデバイスのため記録できません。');
                  return;
                case 10:
                  textField.errorMessages.push('紛失記録されたデバイスのため記録できません。');
                  return;
              }
            }
          } else if (this.mode == 'dispose') {
            // 廃棄記録時
            switch (data.status) {
              case 0:
                if (this.$store.state.user.role.startsWith('reception')) {
                  // デバイス受付センターのスタッフの場合
                  textField.errorMessages.push('未発送のデバイスのため販売組織でのみ記録可能です。');
                  return;
                } else if (data.center_id != this.$store.state.user.org_id) {
                  // 該当販売組織のスタッフでない場合
                  textField.errorMessages.push('このデバイスは他の販売組織で入荷されたものです。');
                  return;
                }
                break;
              case 1:
                textField.errorMessages.push('医療機関に発送済みのデバイスのため、廃棄記録できません。');
                return;
              case 2:
                textField.errorMessages.push('販売スタッフに発送済みのデバイスのため、廃棄記録できません。');
                return;
              case 3:
                textField.errorMessages.push('医療機関に登録済みのデバイスのため、廃棄記録できません。');
                return;
              case 4:
                textField.errorMessages.push('患者に装着後、受付していないデバイスのため、廃棄記録できません。');
                return;
              case 5:
              case 6:
              case 7:
              case 11:
                if (this.$store.state.user.role.startsWith('center')) {
                  // 販売組織のスタッフの場合
                  textField.errorMessages.push('デバイス受付センターに着荷しているデバイスのため、デバイス受付センターでのみ記録可能です。');
                  return;
                } else if (data.reception_center_id !== this.$store.state.user.org_id) {
                  // 該当受付センターのものではない場合
                  textField.errorMessages.push('受付先が異なるデバイスのため記録できません。');
                  return;
                }
                break;
              case 9:
                textField.errorMessages.push('すでに廃棄されたデバイスのため記録できません。');
                return;
              case 10:
                textField.errorMessages.push('紛失記録されたデバイスのため廃棄記録できません。');
                return;
            }
          }
          this.editControl(data);
        }

        try {
          // カーソル自動移動
          let destCursor = this.$refs.recordContinueButton.$el ? this.$refs.recordContinueButton.$el : null;
          if (this.mode == 'collect' && inputType == 'ecg') {
            destCursor = this.$refs.deviceIdFromActivityLogTextField;
          }
          console.log('check start', deviceId, destCursor);

          // 自動で次のindexに移動
          if (destCursor) {
            destCursor.focus();
            this.clearErrorMessages(textField);
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        console.log('remove all errorMessages');
        this.clearErrorMessages(textField);
      }
    },
    getCalendarStr(date) {
      if (!date || date.trim() == '') return '';
      if (
        !/^2[0-9]{3}\/[0-9]{1,2}\/[0-9]{1,2}$/.test(date)
        || !this.isDate(date)
      ) return '';
      const [year, month, day] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    // 各種アクションボタンクリック
    async onClickActionBtn(functionName, item, continueFlag = false) {
      // 処理中の場合は終了
      if (this.isActionBtnDisabled) return;
      this.isActionBtnDisabled = true;
      try {
        // 指定された関数の実行
        await this[functionName](item, continueFlag);
      } finally {
        // 処理中の解除
        this.isActionBtnDisabled = false;
      }
    },
    async editControl(data = null) {
      if (this.currentMode == 'collect') {
        // 入力されているどちらかのデバイスIDを取得
        let deviceId = null;
        if (this.unreadableBarcode) {
          // 封筒が無い
          // 封筒欄の入力とエラーを削除
          this.inputDeviceId = null;
          this.inputEcgType = null;
          this.clearErrorMessages(this.$refs['deviceIdTextField']);
          if (!this.notContainsActivityLog && !this.unreadableBarcodeFromActivityLog) {
            // 行動記録用紙は有る
            // 行動記録用紙欄にエラーがなければ採用
            if (
              this.$refs['deviceIdFromActivityLogTextField'].validate()
              && (
                !this.$refs['deviceIdFromActivityLogTextField'].errorMessages
                || this.$refs['deviceIdFromActivityLogTextField'].errorMessages.length == 0
              )
            ) {
              deviceId = this.inputDeviceIdFromActivityLog;
            }
          } else {
            // 行動記録用紙も無い
            this.inputDeviceIdFromActivityLog = null;
            this.inputEcgTypeFromActivityLog = null;
            this.clearErrorMessages(this.$refs['deviceIdFromActivityLogTextField']);
          }
        } else {
          // 封筒が有る
          if (!this.notContainsActivityLog && !this.unreadableBarcodeFromActivityLog) {
            // 行動記録用紙も有る
            if (this.inputDeviceId == this.inputDeviceIdFromActivityLog) {
              // デバイスIDが一致
              deviceId = this.inputDeviceId;
            }
          } else {
            // 行動記録用紙は無い
            // 行動記録用紙欄の入力とエラーを削除
            this.inputDeviceIdFromActivityLog = null;
            this.inputEcgTypeFromActivityLog = null;
            this.clearErrorMessages(this.$refs['deviceIdFromActivityLogTextField']);
            // 封筒欄にエラーがなければ採用
            if (
              this.$refs['deviceIdTextField'].validate()
              && (
                !this.$refs['deviceIdTextField'].errorMessages
                || this.$refs['deviceIdTextField'].errorMessages.length == 0
              )
            ) {
              deviceId = this.inputDeviceId;
            }
          }
        }
        if (deviceId == null) {
          this.currentItem.remarks = null;
          this.customerOrgName = null;
          this.currentItem.status = null;
          return;
        }
        try {
          const doc = await this.$db.collection('devices').doc(`T-${deviceId}`).get();
          if (!doc.exists) return;
          data = doc.data();
        } catch (e) {
          // 指定されたデバイスへの権限がない場合など
          console.warn(e);
          return;
        }
      }

      if (!data) return;
      this.currentItem.remarks = data.remarks;
      this.customerOrgName = (data.order_org_id && this.$store.state.customerOrgsDict[data.order_org_id]) ? this.$store.state.customerOrgsDict[data.order_org_id].name: data.order_org_id;
      this.currentItem.status = data.status;
    },
    async checkZenHanKey(event) {
      if (event.keyCode === 244 || event.keyCode === 243 || event.keyCode === 25) {
        await this.$root.$alert('警告', '「半角/全角」キーが押されました。全角入力モードになっている可能性があります。バーコードの読み取りは半角入力モードで行ってください。');
      }
    },
    writeLog(tags, message, item, option = null) {
      const deviceType = item.type == 'dongle' ? '通信用ドングル／ソフト' : 'ホルター心電計';
      const deviceId = item.type == 'dongle' ? item.lot_id : item.device_id;
      //tags = ['devices'].concat(tags);
      message = `デバイス：${deviceType} (${deviceId})：${message}`
      this.$functions.log({ tags, message, item: option });
    },
    ecgType2Text(v) {
      if (/^T-[0-9]{6}$/.test(v.device_id)) {
        return this.$store.state.ecgTypes[1];
      }

      if (/^T-[A-Z][0-9]{6}$/.test(v.device_id)) {
        return this.$store.state.ecgTypes[2];
      }
      
      return '';
    },
  },
  mounted() {
    console.log('InventoryDialog was mounted');
    itemsRef = this.$db.collection(`${itemsName}`);
    if (this.item) {
      this.currentItem = JSON.parse(JSON.stringify(this.item));
    }
    this.currentMode = this.mode;
    this.dialogModel = this.value;

    if (this.mode == 'delete') {
      this.deleteItem(this.currentItem);
      return;
    } else if (this.mode == 'new') {
      this.newItemForm();
    } else if (this.mode == 'view') {
      this.viewItemForm();
    } else if (this.mode == 'edit') {
      this.editItemForm();
    } else if (this.mode == 'collect') {
      this.collectItemForm();
    } else if (this.mode == 'dispose') {
      this.disposeItemForm();
    } else if (this.mode == 'lose') {
      this.loseItemForm();
    } else if (this.mode == 'traceability') {
      this.traceabilityItemForm();
    }

  },
  beforeDestroy() {
  },
  watch: {
    value(value) {
      console.log('InventoryDialog value was changed', value);
      this.dialogModel = value;
    },
    item(newItem) {
      console.log('InventoryDialog item was changed', newItem);
      console.log(newItem);
    },
    mode(newMode) {
      console.log('newMode', newMode);
    },
    expirationDateCalStr (val) {
      if (!val || val.trim() == '') return;
      if (!val.match(/^\d{4}-\d{1,2}-\d{1,2}$/)) return;
      this.expirationDateStr = dateformat(new Date(val), 'yyyy/mm/dd');
    },
    producedDateCalStr (val) {
      if (!val || val.trim() == '') return;
      if (!val.match(/^\d{4}-\d{1,2}-\d{1,2}$/)) return;
      this.producedDateStr = dateformat(new Date(val), 'yyyy/mm/dd');
    },
    unreadableBarcode () {
      this.editControl();
    },
    notContainsActivityLog () {
      this.editControl();
    },
    unreadableBarcodeFromActivityLog () {
      this.editControl();
    },
  }
}
</script>

<style scoped>
  .primary {
    color: white;
  }
  .v-select.fit >>> .v-select__selection--comma {
    overflow: inherit;
  }

</style>